import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "@fontsource/fira-code";
import "./index.css";

import {createBrowserRouter, RouteObject, RouterProvider} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import Layout from "./App/_layout";
import Home from "./App/Home";
// import Blogs from "./App/Blogs";
// import Blog from "./App/Blogs/Blog";
import Services from "./components/Services";
import Service from "./App/Service";
import About from "./App/About";
import Contact from "./App/Contact";
import ChatPage from "./components/ChatPage";
import MDParser from "./components/MDParser";
import ScheduleCall from "./App/ScheduleCall";
import NotFound from "./App/NotFound";

import { Theme, ThemeProvider, createTheme, keyframes} from '@mui/material/styles';

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: "", element: <Home /> },
            { path: "contact", element: <Contact /> },
            { path: "about", element: <About /> },
            // { path: "blogs", element: <Blogs /> },
            // { path: "blogs/:slug", element: <Blog /> },
            { path: "services", element: <Services showMore /> },
            { path: "service/:slug", element: <Service /> },
            {path: "chat", element: <ChatPage />},
            { path: 'schedule-call', element: <ScheduleCall /> },
            // Legal Terms and Conditions Pages
            {
                path: 'privacy',
                element: <MDParser markdownFileLoc='/static/MD/Privacy.md' title='Privacy Policies' url='privacy' />
            },
            {
                path: 'terms',
                element: <MDParser markdownFileLoc='/static/MD/Privacy.md' title='Privacy Policies' url='terms' />
            },
            {
                path: 'cookies',
                element: <MDParser markdownFileLoc='/static/MD/Cookies.md' title='Cookie Policies' url='cookies' />
            },
            {
                path: 'returns',
                element: <MDParser markdownFileLoc='/static/MD/Returns.md' title='Return Policies' url='returns' />
            },
            {
                path: 'disclaimers',
                element: <MDParser markdownFileLoc='/static/MD/Disclaimers.md' title='DISCLAIMERS' url='disclaimers' />
            },
        ],
    },
    // TODO: Complete Chatbot Pages
    // TODO: Complete Schedule a Call Pages
    {
        path: '*',
        element: <NotFound />
    }
];

const router = createBrowserRouter(routes, {
    basename: "/",
    window: window,
    future: {
        'v7_fetcherPersist': true,
        'v7_normalizeFormMethod': true,
        'v7_partialHydration': true
    },
    "hydrationData": {}
});
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const components: Theme['components'] = {
    MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    "MuiTypography": {
        "styleOverrides": {
            "h1": {
                "fontSize": "2.5rem",
                "fontWeight": 'bold',
                "lineHeight": 1.167,
                textTransform: 'uppercase',
                animation: `${fadeIn} 1s ease-out`
            },
            overline: {
                textTransform: 'uppercase',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: 2.25,
                letterSpacing: '0.08333em',
                color: "#999",
                animation: `${fadeIn} 1.5s ease-in-out`
            }
        }
    },
    "MuiChip": {
        "styleOverrides": {
            "root": {
                "variants": [
                    {
                        props: { variant: 'outlined' },
                        style: {
                            color: 'text.primary',
                            borderColor: 'text.primary',
                            background: 'transparent'
                        }
                    },
                    {
                        props: { variant: 'filled' },
                        style: {
                            color: '#000',
                            background: '#fff'
                        }
                    }
                ]
            },
            "label": {
                "fontWeight": 500,
                "lineHeight": 1.75,
                "letterSpacing": '0.02857em',
                textTransform: 'uppercase'
            }
        }
    }
}

const darkTheme = createTheme({
    typography:{
        "fontFamily": `"Fira Code", "Courier New", monospace`,
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#000',
            paper: '#1E1E1E'
        }
    },
    components
});
const lightTheme = createTheme({
    typography:{
        "fontFamily": `"Fira Code", "Courier New", monospace`,
    },
    palette: {
        mode: 'light',
    },
    components
});

const deviceTheme = 'dark';
const _theme = deviceTheme === "dark" ? darkTheme: lightTheme;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <ThemeProvider theme={_theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
