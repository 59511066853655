import { Container } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';

interface ScheduleCallProps {

};

export default function ScheduleCall(props: ScheduleCallProps) {

    return (
        <React.Fragment>
            <Helmet>
                <title>Schedule a Call</title>
                <meta name="description" content="Schedule a call with us to discuss your project needs." />
                <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
            </Helmet>

            <Header />

            {/* <!-- Calendly inline widget begin --> */}
            <Container
                className="calendly-inline-widget"
                data-url="https://calendly.com/founder-beyondigital/intro?intro?hide_gdpr_banner=1&primary_color=573cff"
                sx={{
                    display: 'flex',
                    flex: 1,
                    height: '100vh',
                    width: '100vw',
                    overflow: 'auto',
                    mx: 'auto',
                    "::-webkit-scrollbar": {
                        display: 'none'
                    }
                }}
            >
            </Container>
            {/* <!-- Calendly inline widget end --> */}
        </React.Fragment>
    );

};
