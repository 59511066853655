import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme, keyframes } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Icon from './Icon';

export interface Props {
    src: string;
    alt: string;
    title?: string;
    chip?: string;
    slug?: string;
};

export const grainyAnimation = keyframes`
    0% { background-position: 0 0; }
    100% { background-position: 100px 100px; }
`;

const CyberImage = ({ src, alt, title, chip, slug }: Props) => {
    const theme = useTheme();
  return (
    <Box
        sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: '21px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
            transition: 'all 0.3s',
            margin: '0.5rem',
            position: 'relative',
            '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 3,
                    objectFit: 'cover',
                    opacity: 0.15,
                    mixBlendMode: 'difference',
                    background: 'url(/assets/grains.png) repeat',
                    backgroundSize: '100px 100px',
                    backgroundPosition: 'center',
                    animation: process.env.NODE_ENV ==='development' ? undefined: `${grainyAnimation} 1s linear infinite`
                },
            '&:hover': {
                    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
                    "> img": {
                        transformBox: 'fill-box',
                        transform: 'scale(1.25)',
                    },
                    '.MuiIconButton-root': {
                        opacity: 1,
                        right: '1rem',
                    }
                }
            }}
            component={Link}
            href={slug && `/service/${slug}`}
        >
      <Box
            component='img'
            loading='lazy'
            src={src}
            alt={alt}
            sx={{
                width: '100%',
                height: '100%',
                borderRadius: '21px',
                zIndex: 1,
                transition: 'all 0.3s',
                position: 'relative',
                aspectRatio: '1/1',
                objectFit: 'cover',
            }}
       />
        <Box
            component={Stack} spacing={1} justifyContent='space-between' alignItems='flex-start' direction='column'
            sx={{
                position: 'absolute', zIndex: 5, color: 'white',  padding: '1rem', textAlign: 'center',
                top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.125)',
            }}
            >
            {chip && <Chip label={chip} sx={{ fontSize:'1rem', margin:'1rem', padding: '20px 10px'}} variant='filled' />}
            {slug && <IconButton className='btn' sx={{position: 'absolute', right: 0, opacity:0, top: '1rem', transition: 'all 0.3s', backgroundColor: 'text.primary'}} >
                <Icon name='arrow' stroke={theme.palette.background.default} color='InfoText' width={26} height={26} sx={{transform:'rotate(-45deg)'}} />
            </IconButton>}
            {title&& <Typography variant='h4'>{title}</Typography>}
        </Box>
    </Box>
  );
};

export default CyberImage;
