import { Box, BoxProps, useTheme } from '@mui/material';
import React from 'react';

interface IconProps {
    name: 'search' | 'arrow' | 'minimise' | 'chat' | 'send'
};

const chat = (
        <path d="M28.5 14.25C28.5052 16.2298 28.0426 18.1829 27.15 19.95C26.0916 22.0677 24.4646 23.8488 22.4512 25.094C20.4377 26.3391 18.1174 26.9991 15.75 27C13.7702 27.0052 11.8172 26.5426 10.05 25.65L1.5 28.5L4.35 19.95C3.4574 18.1829 2.99484 16.2298 3 14.25C3.00092 11.8827 3.66092 9.56232 4.90608 7.54887C6.15124 5.53542 7.93238 3.90839 10.05 2.85004C11.8172 1.95744 13.7702 1.49488 15.75 1.50004H16.5C19.6265 1.67253 22.5796 2.99218 24.7937 5.20633C27.0079 7.42048 28.3275 10.3735 28.5 13.5V14.25Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
)

const  send = (
        <path d="M16.5 1.5L8.25 9.75M16.5 1.5L11.25 16.5L8.25 9.75M16.5 1.5L1.5 6.75L8.25 9.75"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
);
const minimise = (
        <path d="M8.52886 1.9657V6.29903C8.52886 7.06521 8.2245 7.80001 7.68273 8.34178C7.14096 8.88356 6.40615 9.18792 5.63997 9.18792H1.30664M27.3066 9.18792H22.9733C22.2071 9.18792 21.4723 8.88356 20.9306 8.34178C20.3888 7.80001 20.0844 7.06521 20.0844 6.29903V1.9657M20.0844 27.9657V23.6324C20.0844 22.8662 20.3888 22.1314 20.9306 21.5896C21.4723 21.0478 22.2071 20.7435 22.9733 20.7435H27.3066M1.30664 20.7435H5.63997C6.40615 20.7435 7.14096 21.0478 7.68273 21.5896C8.2245 22.1314 8.52886 22.8662 8.52886 23.6324V27.9657"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
);
const arrow = (
    <>
        <path d="M5 12H19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 5L19 12L12 19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </>
);
const search = (
        <path d="M28.5 28.5L21.975 21.975M25.5 13.5C25.5 20.1274 20.1274 25.5 13.5 25.5C6.87258 25.5 1.5 20.1274 1.5 13.5C1.5 6.87258 6.87258 1.5 13.5 1.5C20.1274 1.5 25.5 6.87258 25.5 13.5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
);

const svg_map = {
    'send': send,
    'chat': chat,
    'minimise': minimise,
    'arrow': arrow,
    'search': search
};
const viewBox_map = {
    'send': '0 0 18 18',
    'chat': '0 0 30 30',
    'minimise': '0 0 29 29',
    'arrow': '0 0 24 24',
    'search': '0 0 30 30'
};

export default function Icon ({
    name, ...props
}: BoxProps<'svg', IconProps>) {
    const theme = useTheme();
    return (
             <Box component='svg' fill='none' stroke={theme.palette.text.primary} {...props} viewBox={viewBox_map[name]} >
               {svg_map[name]}
             </Box>
        );

};
