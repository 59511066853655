import React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import CyberImage, {Props as CIProps} from './CyberImage';
import { ActionButton } from './Button';
import {default as _services} from '../constants/services';
import { keyframes } from '@emotion/react';

interface ServicesProps {showMore?: boolean;};

const services: Array<CIProps> = [
    {
        title: _services['branding'].short_title,
        src: _services['branding'].src,
        alt: _services['branding'].alt,
        chip: 'Design',
        'slug': 'branding'
    },
    {
        title: _services['development'].short_title,
        src: _services['development'].src,
        alt: _services['development'].alt,
        chip: 'Mobile/Web',
        'slug': 'development'
    },
    {
        title: _services['automation'].short_title,
        src: _services['automation'].src,
        alt: _services['automation'].alt,
        chip: 'AI',
        'slug': 'automation'
    },
    {
        title: _services['marketing'].short_title,
        src: _services['marketing'].src,
        alt: _services['marketing'].alt,
        chip: 'Digital',
        'slug': 'marketing'
    }
];

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function Services ({showMore}: ServicesProps) {

    return (
            <Box component={Grid2} container paddingBottom='2rem' >
                {
                    services.map((service, index) => (
                        <Grid2
                            size={{xs:12, md: 6}} key={index}
                            sx={{
                                 animation: `${fadeIn} ${index}s ease-in-out`,
                                ".MuiTypography-root":{fontFamily: 'Inter Tight, san serif', fontWeight:'bold'
                            }}} >
                            <CyberImage {...service} />
                        </Grid2>
                    ))
                }
                   {!showMore &&
                   <Grid2 size={12} sx={{justifyContent:'center', alignItems:'center', display:'flex', margin:'1rem' }} >
                        <ActionButton title='MORE SERVICES' href='/services' />
                   </Grid2>
                }
            </Box>
        );

};
