import React from 'react';
import { Backdrop, Container, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

import TextParser from '../components/TextParser';
import RootLoader from '../loader/Root';
import HeadTitle from './HeadTitle';
import SectionLinks, { SectionsLinksProps, parseMarkdownSections } from './SectionsLinks';

const errorMD = `# Error
Unable to load the requested page.
Please try again later.

~~~lottiefiles
/static/lottie/404.lottie
~~~

`;

export interface MDProps { markdownFileLoc: string; title?: string; url?: string; error?: boolean; skipDescription?: boolean; }

export default function MDParser(
    { markdownFileLoc = '/static/MD/About.md', title = 'About', url = 'about', error = false, skipDescription = false }: MDProps
) {
    const theme = useTheme();
    const [text, setText] = React.useState<string | undefined>(undefined);
    const [sections, setSections] = React.useState<SectionsLinksProps['sections']>([]);
    React.useEffect(() => {
        fetch(markdownFileLoc)
            .then((response) => response.text())
            .then((text) => {
                setText(text);
                const sections = parseMarkdownSections(text);
                setSections(sections);
            })
            .catch((err) => {
                console.error(err);
            });

    }, [markdownFileLoc]);

    return (
        <React.Fragment>

            <Helmet>
                <title>{title} - Beyond Digital</title>
                {!skipDescription &&
                    (title === 'About' ?
                        <meta name="description" content="Beyond Digital, we offer a comprehensive range of services designed to help your business thrive in the digital age. Our expertise spans web and mobile app development, SaaS software solutions, On-Demand Remote developer team, and e-commerce store development. Each service is crafted with precision to ensure top-notch quality, reliability, and innovation. Discover how our tailored solutions can elevate your business." /> :
                        <meta name="description" content={`Beyond Digital's ${title} page`} />
                    )
                }
                {!skipDescription && <meta name="keywords" content="Services, Web Development, Mobile App Development, SaaS Software Solutions, E-commerce Store Development, AI & Machine Learning Services, UI/UX Design Services, Digital Marketing Services, On-Demand Remote Developer Team, Beyond Digital" />}
                {error ? <meta name="robots" content="noindex" /> : null}
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={`${title} - Beyond Digital`} />
                <meta property="og:description" content={`Beyond Digital's ${title} page`} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/static/images/logo.png" />
                <meta property="og:site_name" content="Beyond Digital" />
                <meta property='theme' content={theme.palette.background.default} />
            </Helmet>

            {
                text ? (
                    <Container
                        sx={{
                            display: 'flex', flexDirection: 'column',
                            "h1, h2": { mt: 12 }, "h3, h4": { mt: 9 }, "h5, h6": { mt: 5 },
                            "ul": { mt: 2, "li": { mt: 0, py: 0.5 } },
                            "& .MuiDivider-root": { my: 4 }, pt: 8,
                            '& img': { maxWidth: '100%' },
                            '& .MuiTableContainer-root': { mt: 2, mb: 5 },
                            'strong': { py: 2 }, 'br': { my: 2 },
                            'code': { p: 1, bgcolor: 'rgba(0,0,0,0.1)', borderRadius: 1 },
                        }}
                    >
                        {title && <HeadTitle label={title} />}
                        {error ? <TextParser text={errorMD} /> : <SectionLinks sections={sections}><TextParser text={text} /> </SectionLinks>}
                    </Container>
                ) : <Backdrop open ><RootLoader /></Backdrop>
            }
        </React.Fragment>
    );
};
