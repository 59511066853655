import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, keyframes } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { ParallaxBanner, Parallax as ParallaxEl, ParallaxProvider } from 'react-scroll-parallax';
import ServiceButton from '../components/ServiceButton';
import { ActionButton } from '../components/Button';

interface AboutProps {};


const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${fadeIn} 1s ease-in-out`,
}));

export default function About(props: AboutProps) {
    const [mainBody,setmainBody] = React.useState<HTMLElement|null>(null);

    React.useEffect(() => {
        const _mainBody = window.document.getElementById('root')?.getElementsByTagName('main')[0] as HTMLElement;
        setmainBody(_mainBody);
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Beyond Digital: About</title>
                <meta name="description" content="To revolutionize the way businesses leverage technology, providing top-tier digital services that drive success and growth." />
                <meta name="keywords" content="Web Development, Mobile App Development, SaaS Solutions, E-commerce Development, Remote IT Services" />
                <meta property="og:title" content="Beyond Digital: About" />
                <meta property="og:description" content="To revolutionize the way businesses leverage technology, providing top-tier digital services that drive success and growth." />
            </Helmet>

            <Box component='span' flexDirection='column' display='flex' flex={1} sx={{".MuiTypography-root": {fontFamily: 'Inter Tight, san serif'}}} >

                <ParallaxProvider scrollContainer={mainBody as HTMLElement}>
                    <Box component={Stack}>
                        <ParallaxEl speed={5} scale={[1, 0.9]} opacity={[1, 0.9]}>
                            <Stack spacing={0} direction='column' justifyContent='center' alignItems='center' height='60vh' sx={{margin: '0 auto',zIndex: 2   }} >
                                    <AnimatedTypography variant="overline" gutterBottom>
                                        DIGITAL AGE EXPERTS
                                    </AnimatedTypography>
                                    <AnimatedTypography
                                        variant="h1" gutterBottom
                                        fontSize={{ xs: '5rem', md: '10rem' }}
                                        fontWeight='bold'
                                        textAlign='center'
                                    >
                                        Beyond
                                        <br />
                                        Digital
                                    </AnimatedTypography>
                                </Stack>
                        </ParallaxEl>

                        <ParallaxBanner
                            layers={[
                                {
                                    image: '/assets/about.jpg',
                                    speed: 20,
                                    expanded: true,
                                    scale: [1, 1.5],
                                    opacity: [0.9, 1],
                                },
                            ]}
                            style={{
                                height: '70vh',
                                width: '90%',
                                borderRadius: '12px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 'auto'
                            }}
                        />

                    </Box>
                </ParallaxProvider>

                <Box component={Stack} sx={{padding: '2rem', minHeight: '60vh', justifyContent:'center'}} maxWidth='md' margin='auto' >
                    <AnimatedTypography variant="h4" fontSize={{xs:'1.5rem', md: '2rem'}} gutterBottom>
                        Beyond Digital is a team of digital experts that specialize in web development, mobile app development, SaaS solutions, e-commerce development, and remote IT services. We are dedicated to revolutionizing the way businesses leverage technology, providing top-tier digital services that drive success and growth.
                    </AnimatedTypography>
                </Box>

                <Box component={Stack} sx={{minHeight: '80vh'}} maxWidth='lg' direction='column' justifyContent='center' margin='auto' marginTop='2rem'>
                    <AnimatedTypography variant="overline" gutterBottom textAlign='center'>
                        services
                    </AnimatedTypography>
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
                        <ServiceButton text='branding' img='/assets/z.webp' invert/>
                        <ServiceButton text='APP Development' img='/assets/appd.jpg' />
                        <ServiceButton text='Marketing' img='/assets/x.webp' invert/>
                        <ServiceButton text='automation' img='/assets/automation.webp' />
                    </Stack>
                </Box>

                <Box component={Stack} sx={{minHeight: '80vh'}} maxWidth='lg' direction='column' justifyContent='center' margin='auto' marginTop='2rem'>
                    <AnimatedTypography variant='overline' children="Contact" alignSelf='center' />
                    <AnimatedTypography variant='h2' textAlign='center' fontWeight='bold' fontSize={{ xs: '4rem', md: '7rem' }} >
                        GOT A COOL
                        <br />
                        PROJECT?
                    </AnimatedTypography>
                    <Button
                        variant='contained'
                        href='/contact'
                        children={<ActionButton title="Let's Connect" />}
                        sx={{
                            alignSelf:'center',
                            bgcolor: 'secondary.main',
                            borderRadius: '12px',
                            fontSize: '1.5rem',
                            padding: '5px 25px',
                            margin: '1rem',
                            transition: 'color 1s, background-color 0.5s',
                            ":hover": {
                                color: 'text.primary',
                                bgcolor: 'background.default',
                            },
                            "> *": {
                                color: 'text.primary'
                            }
                        }}
                    />
                </Box>

            </Box>

        </React.Fragment>
    );
}
