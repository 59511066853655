import React from 'react';
import Grid2 from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import {default as TF} from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import {keyframes, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Check from '@mui/icons-material/Check';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

interface ContactProps {service?: string};

const TextField = styled(TF)(theme => ({
    '.MuiInputBase-root': {
        fontSize: '1.25rem',
        minHeight: '60px',
        borderRadius: '12px', // Add border radius here
    },
    '> fieldset': {
        textTransform: 'uppercase'
    },
    backgroundColor: 'background.paper',
    flex:1,
}));

interface FormVar {
    name: string;
    email: string;
    message: string;
    service? : string;
};

const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const checkAnimation = keyframes`
  0% {
    opacity: 0.125;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.65);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

// Create a styled component for the Check icon with the animation
const AnimatedCheck = styled(Check)(({ theme }) => ({
    animation: `${checkAnimation} 2.5s ease-in-out`,
    color: theme.palette.success.main,
    fontSize: '3rem',
    position: 'relative',
    display: 'block',
    marginInline: 'auto'
}));

const AC = () =>(
    <Box sx={{margin: 'auto', display: 'block'}} >
        <AnimatedCheck />
        <Typography variant='overline' children="Inquiry Received!" />
    </Box>
);

const url = (process.env.NODE_ENV === 'development' ? 'http://jae.local:8000': 'https://api.beyondigital.in') + '/developer/inquiries/'

export default function Contact ({service}: ContactProps) {
    const prevStatus = localStorage.getItem(`${service?.replaceAll(' ','-')}-inquiry`);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [completed, setCompleted] = React.useState(prevStatus === 'sent');
    const [loading, setLoading] = React.useState(false);
    const [variables, setVariables] = React.useState<FormVar>({
        service,
        name: '',
        email: '',
        message: ''
    });
console.log(url)

    const _handleTextChange = (_var: 'name' | 'email' | 'message') => (e: any) => {
        setVariables({...variables, [_var]: e.currentTarget.value});
    }

    const _submit = ( ) => {
        if (variables.name.length < 3 || variables.message.length < 3){
            setErrors([...errors, 'Name and message cannot be smaller than 3 characters'])
        }
        else if (!isValidEmail(variables.email)){
            setErrors([...errors, 'Please provide a valid email address.'])
        }
        else {
            const headers = new Headers()
            headers.append('CLIENT-ID', 'EXPO_TEST_KEY')
            headers.append('Content-Type', 'application/json'); // Set Content-Type header
            setLoading(true)
            fetch(url, {
                method: "POST",
                headers,
                body: JSON.stringify(variables)
            }).then(res=>{
                if (res.status === 201){
                    alert("Inquiry Recieved 😉, We'll reach out to you within 2 business days.");
                    setVariables({
                        name: "", email: "", message: ""
                    })
                }
                return res.json()
            }).then(res=> {localStorage.setItem(`${service?.replaceAll(' ','-')}-inquiry`, 'sent'); setCompleted(true)}
            ).catch(e=>setErrors(e)).finally(()=>setLoading(false))
        }
    }

    return (
            <React.Fragment>
                <Grid2 container marginBottom={3} direction={service ?'row-reverse': undefined} >

                    <Grid2 size={{xs:12, md:6}} display='flex' direction='column' marginBottom={{xs:'2rem', md:0}} >
                        {completed?
                        <AC />
                        :<Box component={Stack}>
                            <Stack direction='column' spacing={1} marginTop='auto' marginBottom='auto' >
                                <Typography component='h1' variant='h1' children='Contact' />
                                <Typography
                                    variant='h2'
                                    sx={{
                                        color: 'text.secondary',
                                        padding: '1rem 0',
                                        "span": {color: 'text.primary'},
                                        'a': {textDecoration:'none', color: 'primary.main'},
                                        fontSize: '1.5rem'
                                    }}
                                    >
                                    We’d love to hop on a <a href='tel://+91-9099981021'>Call</a> with you.
                                    Or <span>Email</span> for inquiries.
                                </Typography>
                            </Stack>

                            <Stack direction='column' spacing={1}  >
                                <Stack direction='row' spacing={1} >
                                    <TextField placeholder='NAME' type='text' value={variables.name} onChange={_handleTextChange('name')} />
                                    <TextField placeholder='EMAIL' type='email' value={variables.email} onChange={_handleTextChange('email')} />
                                </Stack>
                                <TextField placeholder='MESSAGE' multiline minRows={5} type='text' value={variables.message} onChange={_handleTextChange('message')} />
                                <LoadingButton
                                     variant='contained' children='Submit'
                                     sx={{
                                        bgcolor: 'text.primary', color: 'background.default',
                                        padding: 1, borderRadius:'12px',fontSize:'1.25rem'
                                    }}
                                    onClick={_submit}
                                    loading={loading}
                                />
                            </Stack>
                        </Box>}
                    </Grid2>

                    <Grid2 size={{xs:12, md:6}} overflow='hidden' >
                        <Box
                            component={DotLottieReact}
                            src={'/static/lottie/hire-developers.lottie'}
                            mode='reverse-bounce'
                            autoplay
                            loop
                            sx={{display:'flex', flex:1, minHeight: {md: '80vh'}, aspectRatio: 1, width:'80vh', alignSelf:'flex-end'}}
                        />
                    </Grid2>

                </Grid2>

                {errors.map((e,i) => {
                        return (
                            <Snackbar
                                    open={true}
                                    autoHideDuration={6000}
                                    onClose={()=> setErrors(errors.filter((_,ix)=> ix===i))}
                                    message="Note archived"
                                    // action={action}
                                />
                        )
                })}

            </React.Fragment>
    );

};
