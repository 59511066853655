import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import services from '../../constants/services';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid2 from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SliderText from '../../components/SliderText';
import Parallax from '../../components/Parallax';
import Contact from '../Contact';

interface ServiceProps {

};

export default function Service (props: ServiceProps) {
    let { slug } = useParams();
    const service = React.useMemo(()=> services[slug as keyof typeof services], [slug])

    return (
        <React.Fragment>

            <Helmet>
            <title>{service.title}</title>
                <meta name="description" content={service.detail_description} />
                <meta name="keywords" content={service.keywords.join(', ')} />
                <meta property="og:title" content={service.title} />
                <meta property="og:description" content={service.detail_description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={service.src} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={service.title} />
                <meta name="twitter:description" content={service.detail_description} />
                <meta name="twitter:image" content={service.src} />
                <meta name="google-site-verification" content="your-google-site-verification-code" />
                <meta property="og:site_name" content="Beyond Digital" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:image:alt" content={service.alt} />
                <meta property="whatsapp:site" content="BEYOND DIGITAL" />
                <meta property="whatsapp:title" content={service.title} />
                <meta property="whatsapp:description" content={service.detail_description} />
                <meta property="whatsapp:image" content={service.src} />
                <meta property="instagram:site" content="https://beyondigital.in" />
                <meta property="instagram:title" content={service.title} />
                <meta property="instagram:description" content={service.detail_description} />
                <meta property="instagram:image" content={service.src} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            {/* Main Display Grid */}
            <Grid2 container>

                <Grid2
                 size={{xs:12, md:4}}component={Stack} justifyContent='space-between' padding={'10px'}
                 sx={{ position: { md: 'sticky' }, top: { md: '100px' }, minHeight: {xs: '60vh', md:'90vh'}, alignSelf:'flex-start' }}
                >
                    <Stack marginBottom='3rem'>
                        <Typography variant='h1'>{service.title}</Typography>
                        <Typography component='h2' variant='overline' sx={{fontSize: '1rem'}}>{service.short_subtitle}</Typography>
                        <Stack direction='row' spacing={2} >
                            {service.keywords.map((w,i)=> <Chip label={w.toUpperCase()} key={i} variant={i%2===0? 'filled':'outlined'} />)}
                        </Stack>
                    </Stack>

                    <Stack direction='column' padding='50px 0px'>
                        <Typography variant='body1' textTransform='uppercase' fontSize={'1.25rem'}>ABOUT</Typography>
                        <Typography variant='body1' textTransform='uppercase' color='textDisabled'  >{service.detail_description}</Typography>
                    </Stack>
                </Grid2>

                <Grid2 size={{xs:12, md:8}}>
                    {
                        service.images.map((src,i)=> {
                            return (
                                    <Parallax
                                        key={i}
                                        src={src}
                                        alt={service.alt}
                                        sx={{
                                            borderRadius: '12px',
                                            overflow:'hidden',
                                            height: '100vh',
                                            width: '100%',
                                            alignSelf:'center',
                                            marginBottom: 1,
                                            maxHeight: {
                                                xs: 'calc(100vw-40px)',
                                                md: '85vh'
                                            },
                                            maxWidth: {
                                                xs: '100%',
                                                md: '65vw'
                                            }
                                        }}
                                />
                            )
                        })
                    }
                </Grid2>

            </Grid2>
            {/*  */}

            <Box>
                <SliderText text={service.slider_text} />
            </Box>

            <Contact service={service.title} />

        </React.Fragment>
    );
};
