//
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import Icon from './Icon';
import Logo from './Logo';
import { ActionButton } from './Button';
import { Helmet } from 'react-helmet';


interface ChatPageProps {};

const ChatContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 100px)',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(3),
    boxShadow: theme.shadows[3],
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin:'auto',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    ".MuiInputBase-root::before": {
        content: 'none',
    }
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
}));

const MessageInputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(3),
    boxShadow: theme.shadows[3],
    padding: "8px 16px",
    '& .MuiTextField-root': {
        marginRight: theme.spacing(2),
    },
    maxWidth: '500px',
    width: '100%',
}));

const baseUrl = process.env.NODE_ENV === 'development'? 'http://jae.local:8000' : 'https://api.beyondigital.in'
const xyjss = 'secret_aibe_beta';

const urls = {
    get_all_messages: (chat_id:string) => `${baseUrl}/developer/messages/?format=json&chat_id=${chat_id}`,
    send_message: `${baseUrl}/developer/messages/?format=json`,
    create_chat: `${baseUrl}/developer/chats/?format=json`,
}

interface Message {role:'user'|'bot'; message: string; created_at: string;};


const roleIcon = {
    'user': <Logo width={'25px'} height='25px' />,
    'bot': <Logo width={'25px'} height='25px' />
};

const ChatPage: React.FC<ChatPageProps> = (props) => {
    const [messages, setMessages] = useState<Array<Message>>([
        {role: 'bot', message: 'Hello! I am Beyond Bot. How can I help you?', created_at: new Date().toISOString()}
    ]);
    const [initFetched, setIn] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [chat_id, setChatId] = useState<string|null>(localStorage.getItem('chat_id'));

    const get_all_messages = React.useCallback(() => {
        if(initFetched){return; }
        if (chat_id === null) {
            fetch(
                urls.create_chat, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CLIENT-ID': xyjss
                },
            })
            .then(response => response.json())
            .then(data => {
                setChatId(data.id);
                localStorage.setItem('chat_id', data.id);
            })
        }
        else{
            fetch(
                urls.get_all_messages(chat_id), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'CLIENT-ID': xyjss
                }
            })
            .then(response => response.json())
            .then(data => {setMessages([...messages, ...data]);})
            .catch(e => {
                console.log(e);
                alert('Oops! Something went wrong. Please try again later.');
            })
            .finally(()=>{setIn(true)})
        }
    }, [chat_id, initFetched, messages]);

    const handleSendMessage = () => {
        if (loading) {return;}
        if (inputValue.trim()) {
            setMessages([...messages, {role: 'user', message: inputValue, created_at: new Date().toISOString()}]);
            setInputValue('');
            setLoading(true);
            fetch(
                urls.send_message, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CLIENT-ID': xyjss
                },
                body: JSON.stringify({chat_id: chat_id, message: inputValue})
            })
            .then(response => response.json())
            .then(data => {setMessages([...messages, ...data]);})
            .catch(e => {
                console.log(e);
                alert('Oops! Something went wrong. Please try again later.');
            })
            .finally(() => setLoading(false));
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    React.useEffect(() => {get_all_messages();}, [get_all_messages]);

    React.useEffect(()=>{chat_id && get_all_messages();}, [chat_id, get_all_messages]);

    return (
        <React.Fragment>

            <Helmet>
                <title>BEYOND BOT: Ai Assistant</title>
                <meta property='description' content="Beyond Digital's Ai powered assistant to help you out." />
            </Helmet>

            <ChatContainer maxWidth='md'  >

                <Stack flexDirection='row' justifyContent='space-between' alignItems='center' width='100%' >
                    <Typography variant="h1" fontSize={{xs:'1.75rem', md:'4rem'}} textAlign='end' gutterBottom>
                        B-BOT
                    </Typography>
                    <ActionButton title='Contact' />
                </Stack>

                <MessagesContainer>
                    <List>
                        {messages.map((_message, index) => {
                            const sameUser = messages.length-1>index && messages[index + 1].role === _message.role;
                            const isBot = _message.role === 'bot';
                            const showDivider = index!==0 && !sameUser && _message.role === 'bot';
                            return (
                            <React.Fragment key={index}>
                                <ListItem divider={showDivider} sx={{flexDirection :isBot ? 'row': 'row-reverse', mb:'0.5rem'}}>
                                    {isBot && <ListItemIcon sx={{width: '1.5rem'}}>
                                        {!sameUser  && roleIcon[_message.role]}
                                    </ListItemIcon>}
                                    <ListItemText
                                        secondaryTypographyProps={{align: isBot ? 'left': 'right'}}
                                        primaryTypographyProps={{align: isBot ? 'left': 'right'}}
                                        primary={_message.message}
                                        secondary={!sameUser && new Date(_message.created_at).toLocaleString()}
                                    />
                                </ListItem>
                            </React.Fragment>
                        )})}

                        {
                            loading &&
                            <ListItem>
                                <ListItemIcon sx={{width: '1.5rem'}}>
                                    <Skeleton
                                            variant='circular'
                                            sx={{height:'30px', width: '30px'}}
                                        />
                                    </ListItemIcon>
                                    <Skeleton
                                        variant='rounded'
                                        sx={{height:'30px', width: '75%'}}
                                    />
                                </ListItem>
                        }

                    </List>
                </MessagesContainer>

                <MessageInputContainer>
                    <TextField
                        fullWidth
                        variant='standard'
                        placeholder="Type a message..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <IconButton
                        color="primary"
                        onClick={handleSendMessage}
                        sx={{ marginLeft: 2 }}
                        children={<Icon name='send' width={'26px'} height={'26px'}/>}
                        disabled={loading}
                    />
                </MessageInputContainer>

            </ChatContainer>

        </React.Fragment>
    );
};

export default ChatPage;
