// @ts-nocheck
// TextParser.js
import React from 'react';
import rehypeRaw from 'rehype-raw';
// TextParser.js
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Typography from '@mui/material/Typography';
import gfm from 'remark-gfm';

import { Box, Divider, Link, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';


const Header = ({ level, ...props }: { level: number } & any) => {
    const variant = `h${level}`;
    var id = undefined;

    if (typeof props.children === 'object' && props.children.type === 'a') {
        id = props.children.props.children.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    }
    else if (typeof props.children === 'string' && props.children.length > 0) {
        id = props.children.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    }

    return <Typography id={id} variant={variant} component={variant} {...props} />;
};

const renderers: Components = {
    p: ({ node, ...props }) => <Typography variant="body1" component='p' {...props} />,
    h1: ({ node, level, ...props }) => <Header {...props} level={1} />,
    h2: ({ node, level, ...props }) => <Header {...props} level={2} />,
    h3: ({ node, level, ...props }) => <Header {...props} level={3} />,
    h4: ({ node, level, ...props }) => <Header {...props} level={4} />,
    h5: ({ node, level, ...props }) => <Header {...props} level={5} />,
    h6: ({ node, level, ...props }) => <Header {...props} level={6} />,
    img: ({ node, ...props }) => <Box component='img' {...props} sx={{ maxWidth: '100%' }} />,
    code(props) {
        const { children, className, node, ...rest } = props;
        const match = /language-(\w+)/.exec(className || '');
        if (className === "language-lottiefiles") {
            return (
                <Box
                    component={DotLottieReact}
                    src={children}
                    mode='bounce'
                    themeData='dark'
                    autoplay
                    loop
                    sx={{
                        minHeight: '100px',
                        py: 2,
                        my: 2,
                        borderRadius: '12px',
                        bgcolor: 'background.paper',
                        boxShadow: theme => `0 2px 4px ${theme.palette.background.paper}`,
                    }}
                />
            )
        }
        return match ? (
            <SyntaxHighlighter
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
            />
        ) : (
            <code {...rest} className={className}>
                {children}
            </code>
        )
    },
    link: ({ node, ...props }) => <Link {...props} />,
    ol: ({ node, ordered, ...props }) => (<List sx={theme => ({ paddingLeft: theme.spacing(4), my: theme.spacing(2) })} {...props} component={'ol'} />),
    ul: ({ node, ordered, ...props }) => (<List sx={theme => ({ paddingLeft: theme.spacing(4), my: theme.spacing(2) })} {...props} component={'ul'} />),
    li: ({ node, ...props }) => <ListItem sx={{ textAlign: 'left', fontFamily: 'Lato, sans-serif' }} {...props} />,
    blockquote: ({ node, ...props }) => <Typography component='blockquote' sx={{
        borderLeft: theme => `2px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        padding: '0.5em 10px',
        margin: '1em 0',
        fontStyle: 'italic',
    }} {...props} />,
    table: ({ node, ...props }) => <TableContainer component={Paper}><Table {...props} /></TableContainer>,
    thead: ({ node, ...props }) => <TableHead sx={{ mx: 1, p: 2 }} {...props} />,
    tr: ({ node, ...props }) => <TableRow {...props} />,
    td: ({ node, ...props }) => (<TableCell component={'td'} {...props} />),
    th: ({ node, ...props }) => (<TableCell component={'th'} {...props} />),
    tbody: ({ node, ...props }) => <TableBody {...props} />,
    hr: ({ node, ...props }) => <Divider component='hr' sx={{ m: 1 }} {...props} />,
    strong: ({ node, ...props }) => <Typography component='strong' fontWeight={700} {...props} />,
    br: ({ node, ...props }) => <br />,
};

const TextParser = ({ text }: { text: string }) => { return <ReactMarkdown rehypePlugins={[rehypeRaw]} components={renderers} children={text} remarkPlugins={[gfm]} />; };

export default TextParser;
