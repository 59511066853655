import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface HeadTitleProps {
    label: string;
};

export default function HeadTitle({ label }: HeadTitleProps) {

    return (
        <Box >
            <Typography children={label} variant='h1'/>
        </Box>
    );

};
