import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';


export default function Logo ({ width="67", height="65",...props}: BoxProps<'svg'>) {

    return (
            <Box component='svg' width={width} height={height} {...props} viewBox="0 0 67 65" xmlns="http://www.w3.org/2000/svg" >
                <Box component='path' opacity="0.25" d="M28.6956 29.6897L20.9302 16.3065L13.0348 2.79327C12.5833 2.03067 11.9572 1.38514 11.2076 0.909307H11.1423C10.3417 1.37087 9.68627 2.04586 9.24987 2.85831L3.37724 12.9932C2.52888 14.4379 2.52888 16.2261 3.37724 17.6709L10.4245 29.8195C10.9465 30.6639 11.1423 31.6384 11.1423 32.6129C11.1367 33.5886 10.8901 34.5479 10.4245 35.4063L3.44253 47.4242C2.59417 48.8689 2.59417 50.6571 3.44253 52.1019L9.31516 62.2368C9.77187 63.0165 10.4245 63.7309 11.2076 64.1858H11.2729C12.0561 63.7309 12.6434 63.0812 13.1001 62.3018L20.9305 48.7889L28.6956 35.2766C29.6741 33.5418 29.6741 31.4245 28.6956 29.6897Z" fill="url(#paint0_linear_1_2)"/>
                <Box component='path' opacity="0.25" d="M66.1505 29.3L58.3201 15.8518L50.2942 2.01403C49.9237 1.38667 49.4365 0.835439 48.8587 0.389867C48.5323 0.130067 48.2058 0 47.8144 0C43.7033 0 40.8974 0.130067 38.8094 0.130067C38.4833 0.130067 38.2874 0.519933 38.3527 0.844767L40.3754 12.1506C40.3754 12.2806 40.3754 12.4104 40.3101 12.5404C39.527 13.9698 39.5923 15.7237 40.4407 17.1531L47.6185 29.6262C48.1405 30.5357 48.4017 31.5102 48.4017 32.4847C48.4017 33.4592 48.1405 34.4337 47.6185 35.3431L40.3754 47.8162C39.5273 49.2456 39.5273 50.9995 40.2448 52.4289C40.3101 52.559 40.3101 52.6887 40.3101 52.8188L38.2874 64.0579C38.2221 64.3827 38.418 64.7726 38.7441 64.7726C40.6366 64.7726 37.961 64.9673 48.0099 64.9673C48.2058 64.9673 48.4667 64.9023 48.6625 64.8373C49.3804 64.5775 49.8371 63.7327 50.2285 63.0183L58.2548 49.1155L66.0199 35.6673C67.2598 33.7173 67.2598 31.3141 66.1505 29.3Z" fill="url(#paint1_linear_1_2)"/>
                <Box component='path' d="M62.9528 29.6899L47.3573 2.79343C46.3786 1.10457 44.5514 0 42.5287 0H11.3377C10.2937 0 9.31494 0.324833 8.40118 0.844433V0.909466C9.15076 1.3853 9.77684 2.03083 10.2284 2.79343L18.0588 16.3067C18.7766 15.9819 19.6248 15.7871 20.4079 15.7871H33.3278C35.3505 15.7871 37.1784 16.8266 38.1564 18.5805L44.6163 29.6899C45.5951 31.3791 45.5951 33.5229 44.6163 35.2771L38.1564 46.3865C37.1777 48.0757 35.2852 49.1799 33.3278 49.1799H20.4729C19.6614 49.1773 18.86 49.0001 18.1237 48.6603L10.2937 62.1732C9.84213 62.9358 9.21605 63.5814 8.46648 64.0572V64.1222C9.31461 64.6418 10.359 64.9667 11.403 64.9667H42.6576C44.6803 64.9667 46.5082 63.9271 47.4862 62.1732L63.0824 35.2768C63.9968 33.5229 63.9968 31.4438 62.9528 29.6899Z" fill="url(#paint2_linear_1_2)"/>
                <Box component='path' d="M25.8241 29.6897L18.0589 16.3065L10.2286 2.79327C9.77702 2.03067 9.15095 1.38514 8.40137 0.909307H8.33742C7.53677 1.37087 6.88135 2.04586 6.44495 2.85831L0.636271 12.9281C-0.21209 14.3729 -0.21209 16.1611 0.636271 17.6059L7.68349 29.7544C8.2055 30.5989 8.40137 31.5734 8.40137 32.5479C8.3957 33.5236 8.14917 34.4829 7.68349 35.3413L0.636271 47.4242C-0.21209 48.8689 -0.21209 50.6571 0.636271 52.1019L6.50923 62.2378C6.96595 63.0175 7.61853 63.7319 8.40171 64.1868H8.467C9.25017 63.7319 9.83747 63.0822 10.2942 62.3028L18.1246 48.7899L25.8893 35.4067C26.8681 33.5227 26.8681 31.4436 25.8241 29.6897Z" fill="url(#paint3_linear_1_2)"/>
                <defs>
                <linearGradient id="paint0_linear_1_2" x1="2.80032" y1="-0.833921" x2="29.4168" y2="-0.833921" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0098BA"/>
                <stop offset="1" stop-color="#4BE3FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1_2" x1="38.3216" y1="-0.83376" x2="67.0134" y2="-0.83376" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0098BA"/>
                <stop offset="1" stop-color="#38D0F2"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1_2" x1="8.40118" y1="-0.833762" x2="63.7527" y2="-0.833762" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0098BA"/>
                <stop offset="1" stop-color="#5EF6FF"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1_2" x1="8.89239e-05" y1="-0.83392" x2="26.6153" y2="-0.83392" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3B92B4"/>
                <stop offset="1" stop-color="#75FFFF"/>
                </linearGradient>
                </defs>
            </Box>
        );

};
