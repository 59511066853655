import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { styled, keyframes } from '@mui/material/styles';

interface ServiceButtonProps {
    text: string;
    img: string;
    invert?: boolean;
};

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.75) translateY(-20px) translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    opacity: 0,
    animation: `${fadeInUp} 1s ease-in-out forwards`,
}));

export default function ServiceButton (props: ServiceButtonProps) {
    const { text, img, invert } = props;
    const [mainBody,setmainBody] = React.useState<HTMLElement|null>(null);

    React.useEffect(() => {
        const _mainBody = window.document.getElementById('root')?.getElementsByTagName('main')[0] as HTMLElement;
        setmainBody(_mainBody);
    }, []);

    const { ref, inView } = useInView({
        threshold: 0.1,
        "root": mainBody
    });

    return (
        <React.Fragment>
            <Stack direction={invert ? 'row-reverse' : 'row'} spacing={2} justifyContent="center" alignItems="center">
                <Box component='img' display={{ xs: 'none', md: 'flex' }} src={img} alt={text} style={{ width: '150px', height: '75px', borderRadius: '37.5px', objectFit: 'cover' }} />
                <AnimatedTypography
                    ref={ref}
                    textAlign='center'
                    variant="h3"
                    key={inView ? 'visible' : 'hidden'}
                    fontWeight='bold'
                    textTransform='uppercase'
                    fontSize={{ xs: '2rem', md: '6rem' }}
                    marginTop={{ xs: 2, md: 0 }}
                    children={text}
                    sx={{
                        animationPlayState: inView ? 'running' : 'paused'
                    }}
                />
            </Stack>
        </React.Fragment>
    );
}
