import React from 'react';
import { useLocation } from 'react-router';

import { ActionButton, TextButton } from '../Button';
import Logo from '../Logo';
import Icon from '../Icon';
import HeaderMenu from './Menu';
import Search from './Search';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { grainyAnimation } from '../CyberImage';

const Header = () => {
    const theme = useTheme();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [searchOpen, setSearchOpen] = React.useState(false);
    const MenuBtn = menuOpen? ActionButton : TextButton;
    const isHomePage = useLocation().pathname === '/';
    const Head = isHomePage? TextButton : ActionButton

    return (
        <React.Fragment>

        <AppBar
            position='absolute'
            sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 1rem',
            boxShadow: 'none',
            height: '100px',
            background:'none',
            flexDirection:'row',
            justifyContent:'space-between',
            top: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                elevation: 1000,
                mixBlendMode: 'difference',
            }}
        >

            <Stack direction='row' spacing={2} component='span' justifyContent='center' alignItems='center' >
                <Logo width='75px' height='75px' />
                <Head title={isHomePage? 'BEYOND': 'GO BACK'} href='/' />
            </Stack>

            <Stack direction='row' spacing={2} component='span' justifyContent='center' alignItems='center' >
            <MenuBtn title={menuOpen?'CLOSE': 'MENU'} onClick={()=> setMenuOpen(!menuOpen)} />
            {!menuOpen && <IconButton
                aria-label='Search'
                onClick={() => setSearchOpen(true)}
                children={<Icon name='search' stroke={theme.palette.text.primary} width={26}height={26} />}
            />}
            </Stack>

        </AppBar>

        <Modal
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            sx={{
                height: '100%',
                width: '100vw',
                display: 'flex',
                zIndex: 999,
                elevation: 999,
                "::before": {
                    content: '""',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 999,
                    elevation: 999,
                    backgroundImage: 'url(/assets/grains.png)',
                    backgroundRepeat: 'repeat',
                    opacity: 0.125,
                    animation: process.env.NODE_ENV ==='development' ? undefined: `${grainyAnimation} 1s linear infinite`
                }
            }}
        >
         <HeaderMenu />
        </Modal>

        <Modal
            open={searchOpen}
            onClose={() => setSearchOpen(false)}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100vw',
                zIndex: 1001,
                elevation: 1001,
                backdropFilter: 'blur(10px)',
            }}
        >
            <Search close={()=>setSearchOpen(false)} />
        </Modal>

        </React.Fragment>
    );
};

export default Header;
