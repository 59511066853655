import React, { PropsWithChildren } from 'react';
import { ArrowUpward } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export interface Section {
    id: string;
    title: string;
    level: number;
    href?: string;
}
export interface SectionsLinksProps {
    sections?: Array<Section>;
};

// Helper function to read and parse a markdown file to extract sections and subsections
export function parseMarkdownSections(markdownContent: string): Array<Section> {

    const headerRegex = /^(#+)\s+(.+)$/gm;
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/; // Matches [title](link)
    let match;
    const sections: Array<Section> = [];

    while ((match = headerRegex.exec(markdownContent)) !== null) {
        const level = match[1].length; // Number of '#' characters
        let title = match[2].trim(); // Header title, trimmed of leading/trailing whitespace
        let href;

        // Check if the title contains a markdown link
        const linkMatch = title.match(linkRegex);
        if (linkMatch) {
            title = linkMatch[1]; // The text part of the markdown link
            // href = linkMatch[2]; // The href part of the markdown link
        }
        href = `#${title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}`;

        sections.push({
            level,
            title: title.replaceAll('**', ''),
            id: title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
            href
        });
    }

    console.log(sections);

    return sections;
}

export default function SectionLinks({ children, sections = [] }: PropsWithChildren<SectionsLinksProps>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentSection, setCurrentSection] = React.useState<string | undefined>(undefined);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setCurrentSection(entry.target.firstChild?.nodeValue?.toString());
                    }
                });
            },
            { rootMargin: '0% 0% -80% 0%' }
        );

        sections.forEach(section => {
            const el = document.getElementById(section.id);
            if (el) { observer.observe(el); }
        });

        return () => {
            sections.forEach(section => {
                const el = document.getElementById(section.id);
                if (el) { observer.unobserve(el); }
            });
        };

    }, [setCurrentSection, sections]);

    return (
        <React.Fragment >
            <Button
                id="sections-button"
                aria-controls={open ? 'sections-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<ArrowUpward />}
                children={
                    <Typography sx={{ fontSize: '16px', color: 'inherit', flex: 1, maxLines: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {currentSection ?? 'Jump to Section'}
                    </Typography>
                }
                sx={{
                    p: 2,
                    borderRadius: '12px',
                    position: 'fixed',
                    left: '15px',
                    bottom: '15px',
                    width: 'auto',
                    maxWidth: { xs: 'calc(100% - 150px)', md: 'calc(100%- 250px)' },
                    color: 'background.default',
                    bgcolor: 'primary.main',
                    zIndex: 1,
                    '&:hover': {
                        bgcolor: 'primary.dark',
                    }
                }}
            />

            <Menu
                id="sections-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                sx={{
                    mb: 2, zIndex: 9999,
                    '& .MuiBackdrop-root': { backdropFilter: 'blur(5px)', zIndex: 9997 },
                    "& .MuiPaper-root": { bgcolor: 'background.default', zIndex: 9999, borderRadius: '18px', width: "100%", bottom: '75px', height: 'fit-content', overflow: 'auto', maxHeight: { xs: '70vh', md: '60vh' } },
                    '& .MuiMenu-list': { bgcolor: 'transparent', m: 0, p: 0, overflow: 'auto' },
                    '& .MuiMenuItem-root': {
                        py: 1, px: 2,
                        '& .MuiTypography-root': {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            flex: 1,
                        }
                    },
                    'a': { textDecoration: 'none' },
                    maxWidth: { xs: '100%', md: '390px' },
                }}
                MenuListProps={{ 'aria-labelledby': 'sections-button' }}
                children={
                    sections.map((section, index) => (
                        <MenuItem
                            key={index}
                            selected={currentSection === section.title}
                            onClick={handleClose}
                            divider={sections[index + 1] && sections[index + 1].level < section.level}
                        >
                            <Typography
                                component={Link} fontWeight={`${600 - (section.level * 100)}`} variant='body1'
                                pl={(section.level - 1) + 'em'} color={currentSection === section.title ? 'primary.main' : 'text.primary'} href={section.href}
                            >
                                {section.title}
                            </Typography>
                        </MenuItem>
                    ))
                }
            />
            {children}
        </React.Fragment >
    );
}
