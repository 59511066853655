import React from 'react';
import { keyframes, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SocialButtons from '../Footer/SocialButtons';

interface HeaderMenuProps {

};

const scroll = keyframes`
    0% { transform: translateX(0%) }
    100% { transform: translateX(-100%) }
`;

function MenuButton ({
    name, url, scrollText, disabled=false
}: {name: string, url: string, scrollText?:string, disabled?:boolean}) {
    const _scrollText = scrollText || `view ${name}`;
    return (
        <Stack direction='row' component='a' position='relative' sx={{flex:1, mixBlendMode:'difference'}}>
            <Button
                disabled={disabled}
                variant='text' href={url} LinkComponent='a'
                sx={{
                    flex:1,
                    padding:0,
                    color: 'text.primary',
                    '&:hover': {
                        '.titleName': {opacity: 0.25},
                        '.scroll-ctr': {opacity: 1},
                        '.scroll-text': {animation: `${scroll} 100s linear infinite`}
                    }
                }}
            >
                <Typography className='titleName' fontWeight='bold' variant='h1' children={name} fontSize={{
                    xs: '3rem',
                    sm: '4rem',
                    md: '5rem',
                    lg: '6rem',
                }}/>

             <Box
                component='span'
                className='scroll-ctr'
                sx={{
                    position: 'absolute',
                    right: 0,
                    left:0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1rem',
                    color: 'text.primary',
                    paddingRight: '1rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backdropFilter: { md: 'blur(10px)'},
                    mixBlendMode: {xs: 'difference', md: 'normal'},
                    opacity: 0,
                    transition: 'all 0.3s',
                }}
            >

                <Box component='span' flexDirection='row' gap={2} className='scroll-text' sx={{ display: 'inline-block' }}>
                    {
                        Array(10).fill(0).flatMap((_, index) => (
                            <Typography
                                key={index} marginRight={'1rem'} variant='overline' fontSize='1.5rem'
                                children={`[ ${_scrollText} ]`}
                            />
                        ))
                    }
                </Box>

            </Box>

            </Button>
        </Stack>
    )
};


const menuItems = [
    {
        name: 'Home',
        url: '/'
    },
    {
        name: 'About',
        url: '/about'
    },
    {
        name: 'Services',
        url: '/services'
    },
    {
        name: 'Blogs',
        url: '/blogs',
        disabled: true
    },
    // {
    //     name: 'Products',
    //     url: '/products'
    // },
    {
        name: 'Contact',
        url: '/contact'
    }
];

export default function HeaderMenu (props: HeaderMenuProps) {
    const theme = useTheme();

    return (
        <Stack
            direction='column' spacing={2} component='span'
            bgcolor={theme.palette.background.default}
            sx={{
                mixBlendMode:'difference', height:'100vh', width:'100vw',
                backdropFilter: 'blur(1000px)', zIndex: 1001, elevation: 1001,
                paddingTop: '100px', justifyContent:'space-between'
            }}
        >
            <Stack direction='column'  component='span' justifyContent='space-evenly' sx={{ flex:1, overflowY:'scroll' }}>
                {menuItems.map((menuItem, index) => (<MenuButton key={index} {...menuItem} />)) }
            </Stack>
            <SocialButtons />
        </Stack>
        );

};
