import React from 'react';
import {  keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from '../components/Logo';

const animate = keyframes`
    0%, 100% {
        transform: scale(1);
        rotate: 0deg;
    }
    50% {
        transform: scale(1.1);
        rotate: 180deg;
    }
    100% {
        transform: scale(1);
        rotate: 360deg;
    }
`;

export default function RootLoader() {

    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: theme => theme.palette.background.paper,
                flexDirection: 'column'
            }}>
            <Logo
                width="256px"
                height="256px"
                borderRadius='32px'
                sx={{ objectFit: 'fill', m: 10, animation: `${animate} 2s infinite` }}
            />
            <Typography variant='caption' children='® Beyond Digital 2024, All rights Reserved' />
        </Box>
    );
};
