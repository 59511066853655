// @ts-nocheck
import * as React from 'react';

import {styled} from '@mui/system';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { useAutocomplete, UseAutocompleteProps } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const StyledAutocompleteRoot = styled('div')(
  ({ theme }) => `
  font-family: "Inter Tight";
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  display: flex;
  gap: 5px;
  padding-right: 5px;
  overflow: hidden;
  width: 100%;
  max-width: 480px;

  &.focused {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInput = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  flex: 1 0 auto;
`,
);

// ComponentPageTabs has z-index: 1000
const StyledPopper = styled('div')`
  position: relative;
  z-index: 1001;
  width: 100%;
  max-width: 480px;
`;

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  border-radius: 12px;
  overflow: auto;
  outline: 0;
  max-height: 300px;
  z-index: 1;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.3)' : 'rgba(0,0,0, 0.05)'
  };
  `,
);

const StyledOption = styled('li')(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `,
);

const StyledPopupIndicator = styled(Button)(
  ({ theme }) => `
    outline: 0;
    box-shadow: none;
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    align-self: center;
    padding: 0 2px;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[700] : blue[100]};
      cursor: pointer;
    }

    & > svg {
      transform: translateY(2px);
    }

    &.popupOpen > svg {
      transform: translateY(2px) rotate(180deg);
    }
  `,
);

const StyledClearIndicator = styled(Button)(
  ({ theme }) => `
    outline: 0;
    box-shadow: none;
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    align-self: center;
    padding: 0 2px;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[700] : blue[100]};
      cursor: pointer;
    }

    & > svg {
      transform: translateY(2px) scale(0.9);
    }
  `,
);

const StyledNoOptions = styled('li')`
  list-style: none;
  padding: 8px;
  cursor: default;
`;

const optionsList = [
    {label: 'Branding', href: '/service/automation/branding'},
    {label: 'Web/Mobile Development', href: '/service/automation/development'},
    {label: 'Remote Staffing', href: '/service/automation/development'},
    {label: 'Digital Marketing', href: '/service/automation/marketing'},
    {label: 'Business Automation', href: '/service/automation/automation'},
    {label: 'Cookies Policy', href: '/cookies'},
    {label: 'Returns Policies', href: '/returns'},
    {label: 'Privacy Policies', href: '/privacy'},
    {label: 'Terms of service', href: '/terms'},
    {label: 'Disclaimers', href: '/disclaimers'},
    {label: "Call us", href: 'tel://+916359337591'},
    {label: "Live Chat", href: '/chat'},
    {label: "Contact Form", href: '/contact'},
    {label: "Schedule Appointment", href: '/schedule-call'},
    {label: 'Github', href: 'https://github.com/orgs/Beyon-Digital/'},
    {label: 'Linkedin', href: 'https://linkedin.com/in/beyond-digital-technologies/'},
    {label: 'Bot', href: '/chat'}
];

const Autocomplete = React.forwardRef(function Autocomplete(
    props: UseAutocompleteProps<(typeof optionsList)[number], false, false, false>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) {
    const {
      disableClearable = false,
      disabled = false,
      readOnly = false,
      ...other
    } = props;

    const {
      getRootProps,
      getInputProps,
      getPopupIndicatorProps,
      getClearProps,
      getListboxProps,
      getOptionProps,
      dirty,
      id,
      popupOpen,
      focused,
      anchorEl,
      setAnchorEl,
      groupedOptions,
    } = useAutocomplete({
      ...props,
      componentName: 'SearchAutocomplete'
    });

    const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;

    const rootRef = useForkRef(ref, setAnchorEl);

    return (
      <React.Fragment>
        <StyledAutocompleteRoot
          {...getRootProps(other)}
          ref={rootRef}
          className={focused ? 'focused' : undefined}
          aria-placeholder='Search ...'
        >
          <StyledInput
            id={id}
            disabled={disabled}
            readOnly={readOnly}
            placeholder='Search ...'
            {...getInputProps()}
          />
          {hasClearIcon && (
            <StyledClearIndicator color='info'  {...getClearProps()}>
              <ClearIcon />
            </StyledClearIndicator>
          )}
          <StyledPopupIndicator
                color='info'
                {...getPopupIndicatorProps()}
                className={popupOpen ? 'popupOpen' : undefined}
            >
                <ArrowDropDownIcon />
          </StyledPopupIndicator>
        </StyledAutocompleteRoot>
        {anchorEl ? (
          <Popper
            open={popupOpen}
            anchorEl={anchorEl}
            slots={{
              root: StyledPopper,
            }}
            modifiers={[
              { name: 'flip', enabled: false },
              { name: 'preventOverflow', enabled: false },
            ]}
          >
            <StyledListbox {...getListboxProps()}>
              {(groupedOptions as typeof optionsList).map((option, index) => {
                const optionProps = getOptionProps({ option, index });

                return <StyledOption {...optionProps}><Button component="a" href={option.href ?? '/'}>{option.label}</Button></StyledOption>;
              })}

              {groupedOptions.length === 0 && (
                <StyledNoOptions>No results</StyledNoOptions>
              )}
            </StyledListbox>
          </Popper>
        ) : null}
      </React.Fragment>
    );
  });

  export default function AutocompleteIntroduction({close}: {close: ()=>void}) {
    return <Autocomplete options={optionsList} onClose={close} />;
  };
