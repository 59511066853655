import React from 'react';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';


interface TBProps extends Exclude<ButtonBaseProps, 'children'> {
    mode?: 'default' | 'tight';
    tightTitle?: string;
    href?: string;
};

const replacementMap: Record<string,string> = {
    "E": "3" ,
    "I": "1" ,
    "O": "0" ,
    "A": "4" ,
    "T": "7" ,
}

export function TextButton({
    mode = 'default',
    sx,
    tightTitle,
    ...props
}: TBProps) {
    const theme = useTheme();
    const defaultTitle = (mode==='default'? props.title : (tightTitle??props.title)) as string;
    const [title, setTitle] = React.useState(defaultTitle as string);
    const [hovering, setHovering] = React.useState(false);
    const convertTextToTight = React.useCallback(() => {
        const l = defaultTitle.replaceAll(/[EIOAT]/g, (m) => replacementMap[m])
        setTitle(l);
    }, [defaultTitle, setTitle]);
    React.useEffect(() => {if (mode === 'tight') { convertTextToTight(); }}, [mode, convertTextToTight]);
    React.useEffect(() => {
        if (hovering) {convertTextToTight();}
        else {setTitle(defaultTitle as string);}
    }, [hovering, defaultTitle, convertTextToTight]);

    return (
            <ButtonBase
                disableRipple disableTouchRipple
                LinkComponent={'a'}
                {...props}
                sx={{...sx, position: 'relative', paddingBottom:'5px', ':hover': {cursor: 'pointer', backgroundColor:'transparent'}}}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontFamily: 'Fira Code',
                        fontSize: {
                            xs: '1.125rem',
                            md: '1.25rem',
                        },
                        color: 'text.primary',
                        '&:hover': {'&::after': {width: '100%'}},
                        '::after': {
                            all: 'unset',
                            content: '""',
                            display: 'block',
                            height: '2px',
                            width: '0%',
                            backgroundColor: theme.palette.text.primary,
                            transition: 'all 0.5s ease',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                        }
                    }}
                    children={title}
                />
            </ButtonBase>
        );

};

export function ActionButton(props: TBProps) {
    return (
        <Typography sx={{fontSize:'1.5rem'}} >
            {"[ "}
                <TextButton {...props} />
            {" ]"}
        </Typography>
    );
};
