import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

interface SliderTextProps {
    text: string;
    rep?: number;
};

// Define keyframes for the sliding animation
const slide = keyframes`
  0% {
    transform: translateX(0%);
  }
    100% {
        transform: translateX(-100%);
  }
`;


export default function SliderText (props: SliderTextProps) {
    const { text } = props;

    // Split the text into sentences
    const sentences = text.split('.').filter(sentence => sentence.trim() !== '');
    // const sentences = [..._sen]
    // for (let i = 0; i < rep; i++) {sentences.push(..._sen)}
    // animation duration should be proportional to avg number of words
      // Calculate the average number of words per sentence
      const totalWords = sentences.reduce((acc, sentence) => acc + sentence.split(' ').length, 0);
    //   const avgWords = totalWords / sentences.length;

    //   // Set the animation duration based on the average number of words
    //   const animationDuration = avgWords * 1; // Adjust the

    return (
        <Box
            sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                height: {xs: '200px', md :'360px'}, // Adjust height as needed
                // animation: `${fadeIn} 20s ease-in-out`, // Apply
                '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    width: '15vw', // Adjust width as needed
                    zIndex:3, elevation:3
                },
                '&::before': {
                    left: 0,
                    background: theme=> `linear-gradient(to right, ${theme.palette.background.default}, transparent)`
                },
                '&::after': {
                    right: 0,
                    background: theme=> `linear-gradient(to left, ${theme.palette.background.default}, transparent)`
                },
            }}
        >
            <Box sx={{display: 'inline-block', animation: `${slide} alternate infinite`, animationDuration:`${totalWords*2}s` }}>
                {sentences.map((sentence, index) => (
                    <Typography
                        key={index}
                        variant='overline'
                        sx={{
                            display: 'inline-block',
                            color: index % 2 === 0 ? 'text.disabled': 'text.primary',
                            fontWeight:'bold',
                            marginRight: '2rem', // Space between sentences
                            fontSize: {xs: "4rem", md: '6rem'},
                            "fontFamily": `Inter Tight`
                        }}
                    >
                        {sentence.trim()}.
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};
