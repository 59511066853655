import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SocialButtons from './SocialButtons';
import  Divider from '@mui/material/Divider';

const links = {
    'services': [
        {title: 'Branding', href: '/service/automation/branding'},
        {title: 'Web/Mobile Development', href: '/service/automation/development'},
        {title: 'Remote Staffing', href: '/service/automation/development'},
        {title: 'Digital Marketing', href: '/service/automation/marketing'},
        {title: 'Business Automation', href: '/service/automation/automation'},
    ],
    'legal': [
        {title: 'Cookies Policy', href: '/cookies'},
        {title: 'Returns Policies', href: '/returns'},
        {title: 'Privacy Policies', href: '/privacy'},
        {title: 'Terms of service', href: '/terms'},
        {title: 'Disclaimers', href: '/disclaimers'},
    ],
    'contact': [
        {title: "Call us", href: 'tel://+916359337591'},
        {title: "Live Chat", href: '/chat'},
        {title: "Contact Form", href: '/contact'},
        {title: "Schedule Appointment", href: '/schedule-call'},
    ]
}

export default function Links () {

    return (
             <Box component={Stack} mt='5rem' mb='2rem' >
                <Grid2 container>

                   {
                        Object.keys(links).map((k)=> {
                            return(
                                <Grid2 size={{xs:12, md: 4}} key={k} >
                                    <List component={Stack} direction='column' >
                                        <ListItem divider>
                                            <ListItemText primary={k} primaryTypographyProps={{fontSize:'1rem', fontWeight:'bold', textTransform:'capitalize',textAlign:{xs: 'start', md: 'center'}}}/>
                                        </ListItem>

                                        {links[k as keyof typeof links]?.map((v,ix) =>
                                            <ListItem component={Button} href={v.href} key={ix}>
                                                <ListItemText secondary={v.title} secondaryTypographyProps={{textAlign:{xs: 'start', md: 'center'}}} />
                                            </ListItem>
                                        )}
                                    </List>
                                </Grid2>
                            )
                        })
                   }
                   <Divider />


                    <Grid2 size={{xs:12}}>
                        <SocialButtons />
                    </Grid2>
                    <Grid2 size={{xs:12}}>
                        <Typography flex={1} textAlign='center' variant='overline' children='© BEYOND DIGITAL 2024' />
                    </Grid2>

                </Grid2>
             </Box>
        );

};
