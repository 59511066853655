import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';

import {Outlet} from 'react-router';

import {useTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function App() {
    const _theme = useTheme();

    return (
        <React.Fragment>

            <Helmet>
                <title>
                    BEYOND DIGITAL – Breaking Boundaries, Redefining IT
                </title>
                <meta name="description" content="At Beyond Digital, we shatter the norms of traditional IT services. Our global network of remote talent empowers businesses to break through limitations and scale with cutting-edge solutions. Trusted by 400+ clients worldwide, we turn ambition into action and lead the way beyond the expected." />
                <link rel="icon" href="/favicon.ico" />
                <meta name="theme-color" content={_theme.palette.background.default} />
            </Helmet>

            <CssBaseline />
            <Box component='main' id='body-main' sx={{overflowY:'scroll', display: 'flex', flex:1, flexDirection:'column' ,"&::-webkit-scrollbar": {display: 'none'}}}>
                <Header />
                <Container maxWidth='xl'  sx={{paddingTop:'100px'}} >
                    <Outlet />
                </Container>
            <Footer />
            </Box>

        </React.Fragment>
  );
};

export default App;
