import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GitHub from '@mui/icons-material/GitHub';
import ChatBubble from '@mui/icons-material/ChatBubble';
import LinkedIn from '@mui/icons-material/LinkedIn';

const socials = [
    {
        name: 'Github',
        url: 'https://github.com/orgs/Beyon-Digital/',
        icon: <GitHub />
    },
    {
        name: 'Linkedin',
        url: 'https://linkedin.com/in/beyond-digital-technologies/',
        icon: <LinkedIn />
    },
    {
        name: 'Bot',
        url: '/chat',
        icon: <ChatBubble />
    }
];


export default function SocialButtons () {

    return (
        <Box
            component={Stack} direction={{xs:'column', md:'row'}} padding='1rem' marginTop='1rem' spacing={2} sx={{
            "> .MuiButton-root": {
                color: 'text.primary',
                transition: 'all 0.3s',
                ":hover": {
                    color: 'text.secondary',
                    scale: 1.1,
                }
            }
        }}>
            {
                socials.map((social, index) => (
                        <Button
                            key={index}
                            variant='text'
                            startIcon={social.icon}
                            href={social.url}
                            target='_blank'
                            LinkComponent='a'
                            sx={{flex:1, margin:'0.5rem'}}
                        >
                            <Typography variant='body1' children={social.name}  />
                        </Button>
                ))
            }
        </Box>
    );
};
