import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Links from './Links';

interface FooterProps {

};

export default function Footer (props: FooterProps) {

    return (
            <Box component='footer' padding={'1rem'} sx={{
                "> .MuiTypography-root": {
                    color: 'text.disabled',
                    "& span": {
                        color: 'text.primary',
                    }
                }
            }} >
                <Typography variant='subtitle1' align='center' gutterBottom>
                    <span>400+ </span>
                    Clients Worldwide. Trusted to Achieve the
                   <span> Unimaginable. </span>
                </Typography>
                <Typography variant='subtitle1' align='center' gutterBottom>
                    From Vision to <span>Victory</span>. Your Future Begins <span>Now</span>.
                </Typography>
                <Links />
            </Box>
        );

};
