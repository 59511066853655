import React from 'react';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';

interface HomeProps {

};

export default function Home (props: HomeProps) {

    return (
             <React.Fragment>
                <HeroSection />
                <Services />
             </React.Fragment>
        );

};
