import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    const theme = useTheme();
  return (
    <React.Fragment>

        <Helmet>

            {/* <meta charset="UTF-8"> */}
            <title> 404 - Lost Beyond </title>
            <meta property='theme' content={theme.palette.background.paper} />
            <meta property="viewport" content="width=device-width, initial-scale=1"/>
            <meta property="description" content="404 - Glitched out"/>
            <meta property="author" content="BDT"/>

            <style>{`
            @import url(https://fonts.googleapis.com/css?family=Gilda+Display);

            body {background-color: ${theme.palette.background.paper}}

            .error {
                text-align: center;
                font-family: 'Gilda Display', serif;

                text-align: center;
                width: 100vw;
                height: 100vh;
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -60px;
                right: 0;
                -webkit-animation: noise-3 1s linear infinite;
                animation: noise-3 1s linear infinite;
                overflow: default;
            }

            body:after {
                content: 'error 404';
                font-family: OCR-A;
                font-size: 100px;

                text-align: center;
                width: 100vw;
                margin: auto;
                position: absolute;
                top: 25%;
                bottom: 0;
                left: 0;
                right: 35%;
                opacity: 0;
                -webkit-animation: noise-1 1s linear infinite;
                animation: noise-1 1s linear infinite;
            }
            body:before {
                content: 'error 404';
                font-family: OCR-A;
                font-size: 100px;

                text-align: center;
                width: 100vw;
                margin: auto;
                position: absolute;
                top: 25%;
                bottom: 0;
                left: 0;
                right: 35%;
                opacity: 0;
                -webkit-animation: noise-2 1s linear infinite;
                animation: noise-2 1s linear infinite;
            }

            .info {
                text-align: center;
                width: 100vw;
                height: 100vh;
                margin: auto;
                position: absolute;
                top: 280px;
                bottom: 0;
                left: 20px;
                right: 0;
                -webkit-animation: noise-3 1s linear infinite;
                animation: noise-3 1s linear infinite;
            }

            .info:before {
                content: 'file not found';
                font-family: OCR-A;
                font-size: 100px;
                text-align: center;
                width: 100vw;
                margin: auto;
                position: absolute;
                top: 20px;
                bottom: 0;
                left: 40px;
                right: 100px;
                opacity: 0;
                color: white;
                -webkit-animation: noise-2 1s linear infinite;
                animation: noise-2 1s linear infinite;
            }

            .info:after {
                content: 'file not found';
                font-family: OCR-A;
                font-size: 100px;
                text-align: center;
                width: 100vw;
                margin: auto;
                position: absolute;
                top: 20px;
                bottom: 0;
                left: 40px;
                right: 0;
                opacity: 0;
                color: white;
                -webkit-animation: noise-1 1s linear infinite;
                animation: noise-1 1s linear infinite;
            }

            @-webkit-keyframes noise-1 { 0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;} 10% {opacity: .1;} 50% {opacity: .5; left: -6px;} 80% {opacity: .3;} 100% {opacity: .6; left: 2px;} }

            @keyframes noise-1 { 0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;} 10% {opacity: .1;} 50% {opacity: .5; left: -6px;} 80% {opacity: .3;} 100% {opacity: .6; left: 2px;} }

            @-webkit-keyframes noise-2 { 0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;} 10% {opacity: .1;} 50% {opacity: .5; left: 6px;} 80% {opacity: .3;} 100% {opacity: .6; left: -2px;} }

            @keyframes noise-2 { 0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;} 10% {opacity: .1;} 50% {opacity: .5; left: 6px;} 80% {opacity: .3;} 100% {opacity: .6; left: -2px;} }

            @-webkit-keyframes noise { 0%, 3%, 5%, 42%, 44%, 100% {opacity: 1; -webkit-transform: scaleY(1); transform: scaleY(1);} 4.3% {opacity: 1; -webkit-transform: scaleY(1.7); transform: scaleY(1.7);} 43% {opacity: 1; -webkit-transform: scaleX(1.5); transform: scaleX(1.5);} }

            @keyframes noise { 0%, 3%, 5%, 42%, 44%, 100% {opacity: 1; -webkit-transform: scaleY(1); transform: scaleY(1);} 4.3% {opacity: 1; -webkit-transform: scaleY(1.7); transform: scaleY(1.7);} 43% {opacity: 1; -webkit-transform: scaleX(1.5); transform: scaleX(1.5);} }

            @-webkit-keyframes noise-3 { 0%,3%,5%,42%,44%,100% {opacity: 1; -webkit-transform: scaleY(1); transform: scaleY(1);} 4.3% {opacity: 1; -webkit-transform: scaleY(4); transform: scaleY(4);} 43% {opacity: 1; -webkit-transform: scaleX(10) rotate(60deg); transform: scaleX(10) rotate(60deg);} }

            @keyframes noise-3 { 0%,3%,5%,42%,44%,100% {opacity: 1; -webkit-transform: scaleY(1); transform: scaleY(1);} 4.3% {opacity: 1; -webkit-transform: scaleY(4); transform: scaleY(4);} 43% {opacity: 1; -webkit-transform: scaleX(10) rotate(60deg); transform: scaleX(10) rotate(60deg);} }

            .wrap { top: 30%; left: 25%; height: 100vh; width: 100vw; margin-top: -100px; position: absolute; }
            code { color: white; }
            span.blue { color: #48beef; }
            span.comment { color: #7f8c8d; }
            span.orange { color: #f39c12; }
            span.green { color: #33cc33; }

            .viewFull { font-family:OCR-A; color:orange; }

            `}</style>

        </Helmet>

        <Box  sx={{display:'flex', flex:1}}>

            <div className="error">

                <div className="wrap">
                <div className="404">
                    <pre>
                        <code>
                            <span className="green">&lt;!</span><span>DOCTYPE html</span><span className="green">&gt;</span>
                            <span className="orange">&lt;html&gt;</span>
                            <span className="orange">&lt;style&gt;</span>
                                {"* {"}
                            <span className="green">everything</span>{":"}<span className="blue">awesome</span>
                                {";}"}
                            <span className="orange">&lt;/style&gt;</span>
                            <span className="orange">&lt;body&gt;</span>
                            ERROR 404!
                                FILE NOT FOUND!
                                <span className="comment">&lt;!--The file you are looking for,
                                    is not where you think it is.--&gt;
                                </span>
                            <span className="orange"></span>

                            <br />
                            <span className="info" />
                            <br />

                            <span className="orange">&nbsp;&lt;/body&gt;</span>

                            <br/>
                    <span className="orange">&lt;/html&gt;</span>
                    </code>
                    </pre>
                </div>

                </div>

            </div>

            <Box
                component={DotLottieReact}
                src={'/static/lottie/404.lottie'}
                mode='bounce'
                autoplay
                loop
                sx={{
                    position:'absolute',
                    top:0, bottom:0, left:0, right:0,
                    zIndex:-1, elevation:-1,
                    borderRadius: '12px',
                    opacity: 2/3
                }}
            />

        </Box>

    </React.Fragment>
  );
};


export default NotFound;
