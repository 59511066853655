const services = {
    'branding': {
        "short_title": "Branding",
        "title": "Branding Solutions",
        "short_subtitle": "Build Your Identity",
        "src": '/assets/branding.jpg',
        "alt": 'Brand Design',
        "keywords": ["branding", "brand identity"],
        "detail_description": "Our branding solutions go beyond just a logo. We craft unique, compelling brand identities that resonate with your audience, establish trust, and set you apart in a competitive market. From visual design to voice and strategy, we create brands that inspire and grow with your business.",
        "slider_text": "Crafting unique brand identities that resonate with your audience. Establish trust and set yourself apart in a competitive market.",
        'images': ['/assets/branding.jpg']
    },
    "development": {
    "short_title": "Development",
    "title": "Digital Marketing",
    "short_subtitle": "Boost Your Reach",
    "src": '/assets/mobile.jpg',
    "alt": 'Mobile & Web Development',
    "keywords": ["digital", "marketing"],
    "detail_description": "Leverage data-driven digital marketing strategies to expand your online reach. We help businesses attract, engage, and convert with targeted campaigns across social media, search engines, and more. Let us boost your visibility, increase traffic, and drive measurable results that fuel growth.",
    "slider_text": "Leverage data-driven strategies to expand your online reach. Boost your visibility and drive measurable results.",
    "images": ["/assets/mobile.jpg"],
    "video": ''
    },
    "automation": {
    "short_title": "Automation",
    "title": "AI Automation",
    "short_subtitle": "Smarter Workflows",
    "src": '/assets/ai.jpg',
    "alt": 'Artificial Intelligence',
    "keywords": ["AI", "business automation"],
    "detail_description": "Transform your operations with AI-driven automation. We integrate AI technologies to optimize workflows, reduce manual tasks, and enhance decision-making. Stay competitive by implementing intelligent automation that boosts efficiency and allows your team to focus on high-impact activities.",
    "slider_text": "Optimize workflows with AI-driven automation. Enhance decision-making and focus on high-impact activities.",
    "images": ["/assets/ai.jpg"],
    "video": ''
    },
    "marketing": {
    "short_title": "Marketing",
    "title": "Web & Mobile Development",
    "short_subtitle": "Innovative Apps",
    "src": '/assets/marketing.jpg',
    "alt": 'Digital Marketing & SEO',
    "keywords": ["web/mobile", "development", " apps"],
    "detail_description": "Create powerful, scalable web and mobile apps tailored to your business needs. Our expert developers craft seamless, user-friendly solutions with the latest technologies to ensure performance and security. From ideation to launch, we help you deliver cutting-edge digital experiences.",
    "slider_text": "Create powerful, scalable web and mobile apps. Deliver cutting-edge digital experiences tailored to your needs.",
    "images": ["/assets/marketing.jpg"],
    "video": ''
    }
}

export default services;
