import React from 'react';

import { keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface HeroSectionProps {

};

const pulse = keyframes`
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(1.25);
        opacity: 0.5;
    }
`;

export default function HeroSection (props: HeroSectionProps) {

    return (
        <Box
            component={Stack}
            direction='column'
            sx={{
                flex:1, display:'flex', flexDirection:'column',
                width: '100%', height: {xs: '60vh',md: '80vh'},
                minHeight: '600px', justifyContent:'center', alignItems:'center',
            }}
        >

            <Stack direction='row' justifyContent='center' alignItems='center' alignSelf='center' >
                <Box
                    sx={{
                        width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'text.primary', marginRight: '1rem',
                        animation: `${pulse} 1s infinite alternate`,
                    }}
                />
                <Typography variant='h6' color='textDisabled'>1600+ Remote Developers</Typography>
            </Stack>

            <Typography
                variant='h1'
                sx={{
                    fontFamily: 'Inter Tight, san serif',
                    fontSize: {
                        xs : '3rem',
                        sm : '6.5rem',
                        md: '8rem',
                    },
                    color: 'text.primary',
                    lineHeight: {
                        xs: '3rem',
                        sm: '6.5rem',
                        md: '8rem',
                    },
                    textAlign: 'center',
                    fontWeight: 'bold',
                    alignSelf:'center'
                }}>
                BEYOND <br/> BOUNDARIES
            </Typography>

        </Box>
        );

};
