import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Parallax as ParallaxEl } from 'react-parallax';

interface ParallaxProps extends BoxProps<'span', {  src: string; alt?: string, strength?: number }> {}

export default function Parallax(props: ParallaxProps) {
    const { src, alt, children, strength=200, sx, ...rest } = props;
    const [mainBody,setmainBody] = React.useState<HTMLElement|null>(null);

    React.useEffect(() => {
        const _mainBody = window.document.getElementById('root')?.getElementsByTagName('main')[0] as HTMLElement;
        setmainBody(_mainBody);
    }, []);

    // const observer = new IntersectionObserver(handleIntersection, {
    //     root: null,
    //     threshold: 0.1,
    // });

    return (
            <Box
                component='span'
                sx={{ overflow: 'hidden', display: 'block', position: 'relative', height:'100%', width:'100%', borderRadius: 12, ...sx }}
                {...rest}
            >
                <ParallaxEl
                    bgImage={src}
                    bgImageAlt={alt}
                    strength={strength}
                    parent={mainBody as HTMLElement}
                    style={{display:'flex', flex:1, height: '100%', width: '100%'}}
                    children={children}
                />
            </Box>
    );
}
